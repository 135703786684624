import { Switch, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    const userInfo = {
        walkInEnabled: false,
    }
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({...userInfo});
    const global = useContext(GlobalContext);
    const _id = props._id;
    const closeParentModal = props.handleClose;
    const classes = combinedStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    let org = {};
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg;
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg;
    }

    const pullServices = async () => {
        let resultDoctor;
        let pulledServices;
        let pulledModes;
        let walkInEnabled;
        try {
            resultDoctor = await global.api.getProviderDoctorByID(_id, org._id);
            pulledServices = resultDoctor.data.data.data.org_services;
            pulledModes = resultDoctor.data.data.data.modes;
            walkInEnabled = resultDoctor.data.data.data.settings && resultDoctor.data.data.data.settings[org._id] 
                ? resultDoctor.data.data.data.settings[org._id].allowWalkIns 
                : false;
        } catch (error) {
            console.log(error.response ? error.response.data : error);
            return;
        }

        let arr = [];
        for(let i = 0; i < org.services.length; i++){
            if(pulledServices && pulledServices !== null && pulledServices.includes(org.services[i].name)){
                arr.push({id:i, name:org.services[i].name, enabled: true});
            } else{
                arr.push({id:i, name:org.services[i].name, enabled: false});
            }
        }

        let arr2 = [];
        if(org?.org_services) {
            let tempModesArray = Object.keys(org.org_services).map(key => key);
            let modesObject = await global.api.translateAppintmentModeArray(tempModesArray);
            tempModesArray = Object.keys(org.org_services).map(key => {
                return {key:key, enabled: org.org_services[key]};
            });

            for(let i = 0; i < modesObject.data.length; i++) {
                let index = tempModesArray.findIndex((mode) => (mode.key === modesObject.data[i]._id));
                if(index !== -1 && tempModesArray[index].enabled) {
                    if(pulledModes && pulledModes !== null && pulledModes.includes(tempModesArray[index].key)) {
                        arr2.push({id: modesObject.data[i]._id, name: modesObject.data[i].name, enabled: true});
                    } else {
                        arr2.push({id: modesObject.data[i]._id, name: modesObject.data[i].name, enabled: false});
                    } 
                }
            }
            setState({...state, walkInEnabled: walkInEnabled, services: arr, modes: arr2});
        }
    };

    React.useEffect(() => {
        pullServices();
    }, [props, global.state.selectedOrg, global.state.defaultOrg]);

    const handleClose = () => {
        closeParentModal();
        setOpen(false);
    };

    const handleChangeModes = id => (event) => {
        if (id === undefined) return;
        let index = state.modes.findIndex(x => String(x.id) === String(id));
        if (index === -1) return;
        setState({...state, modes: [
            ...state.modes.slice(0, index),
            {id: state.modes[index].id, name: state.modes[index].name, enabled: event.target.checked},
            ...state.modes.slice(index + 1)
        ]});
    };

    const handleChangeServices = id => (event) => {
        if (id === undefined) return;
        let index = state.services.findIndex(x => String(x.id) === String(id));
        if (index === -1) return;
        setState({...state, services: [
            ...state.services.slice(0, index),
            {id: state.services[index].id, name: state.services[index].name, enabled: event.target.checked},
            ...state.services.slice(index + 1)
        ]});
    };

    const handleSave = async () => {
        const tempArr = state.services.filter(s => s.enabled).map(s => s.name);
        try {
            await global.api.updateProviderServices(org._id, _id, tempArr);
            const tempArr2 = state.modes.filter(m => m.enabled).map(m => m.id);
            await global.api.updateDoctorModes(org._id, _id, tempArr2);
            await global.api.updateProviderAllowWalkIns({
                orgId: org._id,
                doctorId: _id,
                allowWalkIns: state.walkInEnabled
            });
        } catch(error) {
            console.log("Appointment settings update error", error);
        }
        handleClose();
    };

    const handleChangeWalkIn = () => {
        setState({...state, walkInEnabled: !state.walkInEnabled});
    };

    // Sort services to show enabled ones first
    const sortedServices = state.services 
        ? [...state.services].sort((a, b) => b.enabled - a.enabled)
        : [];

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}>
                <Typography className={classes.typography}>Edit Appointment Setting</Typography>
            </ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle 
                    className={classes.dialogTitle} 
                    style={{ textAlign: "center", marginBottom: -40, marginTop: 10 }} 
                    id="alert-dialog-slide-title"
                >
                    <IconButton 
                        onClick={handleClose} 
                        className={classes.dialogClose} 
                        children={<ClearIcon />} 
                    />
                    <h3>Edit Appointment Settings</h3>
                </DialogTitle>

                <DialogContent className={classes.content} style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        checked={state.walkInEnabled}
                                        onChange={handleChangeWalkIn} 
                                        color="primary" 
                                        size="small"
                                        name="Accept Walk-In Patients"
                                    />
                                }
                                label="Accept Walk-In Patients"
                                style={{ color: "black" }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography 
                                style={{ fontSize: 18, fontWeight: 700, marginBottom: 8 }} 
                                className={classes.labelSmall}
                            >
                                Appointment Type
                            </Typography>
                            <Grid container spacing={1}>
                                {org && org.org_services && state && state.modes && state.modes.map(mode => (
                                    <Grid item xs={6} key={mode.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={mode.enabled}
                                                    onChange={handleChangeModes(mode.id)}
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Typography style={{ fontSize: 14 }}>
                                                    {mode.name}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography 
                                style={{ fontSize: 18, fontWeight: 700, marginBottom: 8 }} 
                                className={classes.labelSmall}
                            >
                                Appointment Reason
                            </Typography>
                            <Grid 
                                container 
                                style={{ 
                                    maxHeight: '180px', 
                                    overflowY: 'auto', 
                                    padding: '8px', 
                                    border: '1px solid #ddd', 
                                    borderRadius: '4px',
                                    backgroundColor: '#fafafa'
                                }}
                            >
                                {org && org.services && state && sortedServices.map(service => (
                                    <Grid item xs={12} key={service.id} style={{ marginBottom: 4 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    color="primary" 
                                                    checked={service.enabled} 
                                                    onChange={handleChangeServices(service.id)} 
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Typography style={{ fontSize: 14 }}>
                                                    {service.name}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogAction} style={{ padding: '0 20px 20px' }}>
                    <Button 
                        style={{ fontWeight: 700, minWidth: 100 }} 
                        onClick={handleClose} 
                        color="primary" 
                        className={classes.actionBtn}
                    >
                        Cancel
                    </Button>
                    <Button 
                        style={{ fontWeight: 700, minWidth: 100 }} 
                        onClick={handleSave} 
                        color="primary" 
                        variant="contained"
                        className={classes.actionBtn}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}